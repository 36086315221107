<template>
  <div class="container">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title is-centered">
          <span class="tag is-success">Verified at {{date}}</span>
        </p>
      </header>
      <div class="card-content">
        <highlightjs language='javascript' :code="this.payload.code"/>
      </div>
      <footer class="card-footer">
        <a href="#" class="card-footer-item" @click="copyToClipboard">Copy</a>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Payload',
  props: {
    payload: {
      code: String,
      verifiedAt: Date
    }
  },
  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.payload.code);
    }
  },
  computed: {
    date() {
      return new Date(this.payload.verifiedAt).toLocaleString();
    }
  }
}
</script>

<style lang="scss">
  .card {
    margin-bottom: 2rem;

  }
</style>
<template>

  <section class="hero">
    <div class="hero-body">
      <p class="title has-text-centered">
        Cloudflare WAF XSS tested/updated bypasses
      </p>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'AppHeader',
  props: {
    msg: String
  }
}
</script>

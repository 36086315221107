<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        Made with <span style="color: #e25555;">&#9829;</span> by <a href="https://twitter.com/7ouss3m">Houssem</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'AppFooter',
}
</script>

<style lang="scss">

</style>
